import { Breakpoint, Dialog as MUIDialog, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type DialogProps = {
  disableEscapeKeyDown?: boolean;
  fullWidth?: boolean;
  maxWidth?: Breakpoint | false;
  onClose?: () => void;
  open: boolean;
  sx?: SxProps;
};

const Dialog = ({
  children = null,
  disableEscapeKeyDown,
  fullWidth,
  maxWidth,
  onClose,
  open,
  sx,
}: PropsWithChildren<DialogProps>) => {
  return (
    <MUIDialog
      PaperProps={{
        sx: [
          {
            minWidth: '300px',
            p: 0,
          },
        ],
      }}
      disableEscapeKeyDown={disableEscapeKeyDown}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={onClose}
      open={open}
      sx={sx}
    >
      {children}
    </MUIDialog>
  );
};

export default Dialog;
