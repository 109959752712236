import { faExclamationCircle as iconError } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { Alert } from '@mui/material';
import { trimEnd } from 'lodash';

import { ALL_FIELD } from '@helpers/api';
import { getUniqueId } from '@helpers/utils';

import Icon from '../Icon';

const prepareMessage = (msg: string) => `${trimEnd(msg, '.,;')}.`;

type FormErrorsProps = {
  errors?:
    | Record<string, any>
    | null
    | {
        [ALL_FIELD]: string | string[];
      };
};

const FormErrors = ({ errors }: FormErrorsProps) => {
  const error = (errors && errors[ALL_FIELD]) || null;

  let messages: string[] = [];
  if (error) {
    messages = Array.isArray(error)
      ? error.map((e) => prepareMessage(e))
      : [prepareMessage(error)];
  }

  let result = null;
  if (messages.length > 0) {
    result = (
      <Alert
        icon={<Icon icon={iconError} />}
        severity="error"
        sx={{ my: 1 }}
      >
        {messages.map((msg) => {
          return <div key={getUniqueId()}>{msg}</div>;
        })}
      </Alert>
    );
  }
  return result;
};

export default FormErrors;
