import { useCallback } from 'react';

const useSetLock = <T = string>(enabled: boolean, storage: Set<T>) => {
  const isLocked = useCallback(
    (...keys: T[]) => {
      if (!enabled) {
        return false;
      }
      return keys.reduce((previous, key) => previous && storage.has(key), true);
    },
    [enabled, storage],
  );

  const setLock = useCallback(
    (...keys: T[]) => {
      if (!enabled) return;
      keys.forEach((v) => storage.add(v));
    },
    [enabled, storage],
  );

  const releaseLock = useCallback(
    (...keys: T[]) => {
      if (!enabled) return;
      keys.forEach((v) => storage.delete(v));
    },
    [enabled, storage],
  );
  return { isLocked, setLock, releaseLock };
};

export default useSetLock;
