import { intersection } from 'lodash';

import Enum from './enum';
import { GuidString } from './typeGuards';

// this constants should be kept in sync with Django API service.
const PERMISSIONS = Enum({
  // Analytics
  VIEW_ANALYTICS: ['can_view_analytics', 'Can view Organic Analytics features'],

  // Ads app
  VIEW_AD_SETTINGS: ['can_view_ad_settings', 'Can view ad settings'], // not used in UI for now
  VIEW_AD_PLACEMENTS: ['can_view_ad_placements', 'Can view ad placements'],
  EDIT_AD_PLACEMENTS: ['can_edit_ad_placements', 'Can edit ad placements'],
  VIEW_AD_DEMAND_PARTNERS: [
    'can_view_ad_demand_partners',
    'Can view ad demand partners',
  ],
  EDIT_AD_DEMAND_PARTNERS: [
    'can_edit_ad_demand_partners',
    'Can edit ad demand partners',
  ],
  VIEW_AD_RULES: ['can_view_ad_rules', 'Can view ad rules'],
  EDIT_AD_RULES: ['can_edit_ad_rules', 'Can edit ad rules'],
  EDIT_SITE_ADS_TXT: ['can_edit_site_ads_txt', 'Can edit site ads.txt'],
  VIEW_AD_REFRESH_RATES: [
    'can_view_ad_refresh_rates',
    'Can view ad refresh rates',
  ],
  EDIT_AD_REFRESH_RATES: [
    'can_edit_ad_refresh_rates',
    'Can edit ad refresh rates',
  ],
  EDIT_AD_LAZYLOAD_SETTINGS: [
    'can_edit_ad_lazyload_settings',
    'Can edit ad lazy load settings',
  ],
  VIEW_REPORTS: ['can_view_reports', 'Can view reports'],

  // Affiliate app
  VIEW_MERCHANTS: ['can_view_merchants', 'Can view merchants'],
  EDIT_MERCHANTS: ['can_edit_merchants', 'Can edit merchants'],
  EDIT_PRODUCTS: ['can_edit_products', 'Can edit products'],
  EDIT_AFFILIATE_SETTINGS: [
    'can_edit_affiliate_settings',
    'Can edit affiliate settings',
  ],

  // Campaigns app
  VIEW_CAMPAIGNS: ['can_view_campaigns', 'Can view campaigns'],
  EDIT_CAMPAIGNS: ['can_edit_campaigns', 'Can edit campaigns'],
  VIEW_MAILINGS: ['can_view_mailings', 'Can view mailings for an org'],

  // Content app
  // @todo

  // Site
  EDIT_SITE: ['can_edit_site', 'Can edit site'],

  // Organization
  VIEW_ORGANIZATION: ['can_view_organization', 'Can view organization'], // not used in UI for now
  EDIT_ORGANIZATION: ['can_edit_organization', 'Can edit organization'], // not used in UI for now

  // Publisher
  VIEW_PUBLISHER: ['can_view_publisher', 'Can view publisher'], // not used in UI for now
  EDIT_PUBLISHER: ['can_edit_publisher', 'Can edit publisher'], // not used in UI for now

  // Permissions
  MANAGE_MEMBERS: ['can_manage_members', 'Can manage members'],
  MANAGE_ROLES: ['can_manage_roles', 'Can manage roles'], // not used in UI for now

  // System
  VIEW_API_KEY: ['can_view_api_key', 'Can view API key'],

  // Deprecated or unused by UI (others above may also be unused)
  VIEW_CONTENT_REPORTS: [
    'can_view_content_reports',
    'Can view content reports',
  ],
  VIEW_CONTENT_REPORT_AD_REVENUE: [
    'can_view_content_reports_ad_revenue',
    'Can view ad revenue in content reports',
  ],
  VIEW_SOCIAL: ['can_view_social', 'Can view Social app'],
  VIEW_SOCIAL_ACCOUNTS: [
    'can_view_social_accounts',
    'Can view Social Accounts for an org',
  ],
  EDIT_SOCIAL_ACCOUNTS: [
    'can_edit_social_accounts',
    'Can edit Social Accounts for an org',
  ],
  VIEW_SOCIAL_POSTS: [
    'can_view_social_posts',
    'Can view Social Posts for an org',
  ],
  VIEW_AUDIENCE: ['can_view_audience', 'Can view audience'],
  VIEW_AUDIENCE_HISTORY: [
    'can_view_history',
    'Can view audience history for an org',
  ],
});

const MODE_ANY_SITE = 'anySite';
const MODE_ALL_SITES = 'allSites';
const MODE_ANY_PERMISSION = 'anyPermission';
const MODE_ALL_PERMISSIONS = 'allPermissions';

export type SiteMode = typeof MODE_ANY_SITE | typeof MODE_ALL_SITES;
export type PermissionMode =
  | typeof MODE_ANY_PERMISSION
  | typeof MODE_ALL_PERMISSIONS;
export type SitePermission = {
  readonly siteGuid: GuidString;
  readonly permissionCode: string;
};
export type UserPermission = {
  [k: GuidString]: string[];
};

const parseGqlPermissions = (perms?: SitePermission[] | null) => {
  const userPermission: UserPermission = {};
  (perms || []).forEach(({ siteGuid, permissionCode }) => {
    if (!Object.prototype.hasOwnProperty.call(userPermission, siteGuid)) {
      userPermission[siteGuid] = [permissionCode];
      return;
    }
    if (!userPermission[siteGuid].includes(permissionCode)) {
      userPermission[siteGuid].push(permissionCode);
    }
  });
  return userPermission;
};

/* Check for permission(s) across user site(s) */
const hasPermissions = (
  userPermissions: UserPermission,
  perms: string | string[],
  sites: GuidString | GuidString[] | null,
  permMode: PermissionMode = MODE_ALL_PERMISSIONS,
  siteMode: SiteMode = MODE_ANY_SITE,
) => {
  if (![MODE_ALL_SITES, MODE_ANY_SITE].includes(siteMode)) {
    throw Error('Incorrect siteMode parameter');
  }
  if (![MODE_ALL_PERMISSIONS, MODE_ANY_PERMISSION].includes(permMode)) {
    throw Error('Incorrect permMode parameter');
  }

  // normalize input data to perform checks for
  const checkedPermissions = typeof perms === 'string' ? [perms] : perms;
  const checkedSites = (typeof sites === 'string' ? [sites] : sites) || [];

  // permissionEntry is an array [siteGuid, sitePermissions] to perform check for a site
  let checkingPermissionEntries: [GuidString, string[]][] = [];
  if (checkedSites.length > 0) {
    // let's limit check scope down to selected sites
    checkedSites.forEach((siteGuid) =>
      checkingPermissionEntries.push([
        siteGuid,
        userPermissions[siteGuid] || [],
      ]),
    );
  } else {
    checkingPermissionEntries = Object.entries(userPermissions) as [
      GuidString,
      string[],
    ][];
  }

  const checkPermissionEntry = ([, sitePermissions]: [any, string[]]) => {
    const hasSitePermission =
      permMode === MODE_ALL_PERMISSIONS
        ? // ensure the user has all checking permissions for a site
          intersection(sitePermissions, checkedPermissions).length ===
          checkedPermissions.length
        : // ensure the user has any of checking permissions for a site
          intersection(sitePermissions, checkedPermissions).length > 0;
    return hasSitePermission && checkedPermissions.length > 0;
  };

  if (siteMode === MODE_ALL_SITES) {
    // ensure the user has checking permissions for all accessible sites
    return checkingPermissionEntries.length > 0
      ? checkingPermissionEntries.every(checkPermissionEntry)
      : false;
  }
  // ensure the user has checking permissions for any of accessible sites
  return checkingPermissionEntries.length > 0
    ? checkingPermissionEntries.some(checkPermissionEntry)
    : false;
};

export {
  hasPermissions,
  MODE_ALL_PERMISSIONS,
  MODE_ALL_SITES,
  MODE_ANY_PERMISSION,
  MODE_ANY_SITE,
  parseGqlPermissions,
  PERMISSIONS,
};
