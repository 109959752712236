import { LoadingButton } from '@mui/lab';
import { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { Button, DialogActions as MUIDialogActions } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import { EventHandler, ReactNode } from 'react';

type DialogActionsProps = {
  children: ReactNode;
  primaryButtonText?: string;
  secondaryButtonText?: string | null;
  onPrimaryButtonClick?: EventHandler<any>;
  onSecondaryButtonClick?: EventHandler<any>;
  primaryLoading?: boolean;
  primaryDisabled?: boolean;
  secondaryDisabled?: boolean;
  primaryType?: LoadingButtonProps['type'];
  secondaryType?: ButtonProps['type'];
};

const DialogActions = ({
  children,
  onPrimaryButtonClick,
  onSecondaryButtonClick,
  primaryButtonText,
  secondaryButtonText,
  primaryLoading,
  primaryDisabled,
  primaryType,
  secondaryDisabled,
  secondaryType,
  ...rest
}: DialogActionsProps) => {
  return (
    <MUIDialogActions
      sx={{
        boxShadow: 5,
        mt: 0,
        backgroundColor: (theme) => theme.palette.background.paper,
        position: 'sticky',
        bottom: 0,
      }}
    >
      {children}
      {secondaryButtonText && (
        <Button
          disabled={secondaryDisabled}
          onClick={onSecondaryButtonClick}
          type={secondaryType}
          autoFocus
        >
          {secondaryButtonText}
        </Button>
      )}
      <LoadingButton
        disabled={primaryDisabled}
        loading={primaryLoading}
        onClick={onPrimaryButtonClick}
        type={primaryType}
        variant="contained"
        {...rest}
      >
        {primaryButtonText}
      </LoadingButton>
    </MUIDialogActions>
  );
};

DialogActions.propTypes = {
  children: PropTypes.node,
  primaryButtonText: PropTypes.string,
  secondaryButtonText: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func,
  primaryLoading: PropTypes.bool,
  primaryDisabled: PropTypes.bool,
  secondaryDisabled: PropTypes.bool,
  primaryType: PropTypes.string,
  secondaryType: PropTypes.string,
};

DialogActions.defaultProps = {
  children: null,
  primaryButtonText: 'Confirm',
  secondaryButtonText: null,
  onPrimaryButtonClick: noop,
  onSecondaryButtonClick: noop,
  primaryLoading: false,
  primaryDisabled: false,
  secondaryDisabled: false,
  primaryType: null,
  secondaryType: null,
};

export default DialogActions;
