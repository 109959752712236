import { TextField, TextFieldProps } from '@mui/material';
import { useField } from 'formik';
import { useCallback } from 'react';

const FormikTextField = ({
  name,
  helperText = ' ',
  margin = 'dense',
  ...textFieldProps
}: TextFieldProps) => {
  const [field, meta] = useField<string>(name!);
  const hasError = textFieldProps.error || Boolean(meta.error);

  const onBlur = useCallback<NonNullable<TextFieldProps['onBlur']>>(
    (e) => {
      textFieldProps.onBlur?.(e);
      field.onBlur(e);
    },
    [field, textFieldProps],
  );

  const onChange = useCallback<NonNullable<TextFieldProps['onChange']>>(
    (e) => {
      textFieldProps.onChange?.(e);
      field.onChange(e);
    },
    [field, textFieldProps],
  );

  return (
    <TextField
      {...textFieldProps}
      error={hasError}
      helperText={meta.error || helperText}
      margin={margin}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      value={textFieldProps.value ?? field.value}
    >
      {textFieldProps.children}
    </TextField>
  );
};

export default FormikTextField;
