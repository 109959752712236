import { FormikErrors, FormikValues } from 'formik';
import { isObject, trimEnd } from 'lodash';

import { ActionError } from './api';

const hasFieldErrors = (errors?: ActionError | FormikErrors<FormikValues>) => {
  if (!isObject(errors)) {
    return false;
  }
  const fieldKeys = Object.keys(errors).filter((k) => k !== 'genericErrors');

  let hasError = false;
  fieldKeys.forEach((key) => {
    const fieldError = errors[key];
    if (Array.isArray(fieldError)) {
      fieldError.forEach((error) => {
        if (typeof error === 'string') {
          hasError = true;
        }
      });
    }
    if (typeof fieldError === 'string') {
      hasError = true;
    }
  });
  return hasError;
};

// TODO: deprecate in favour Formik* fields
/* Helper to extract formik errors for MUI */
const getFieldError = (
  errors: ActionError | { [k: string]: string },
  touchedFields: Record<string, boolean>,
  field: string,
) => {
  const touched = touchedFields ? touchedFields[field] || false : false;
  const fieldErrors = touched && errors ? errors[field] || null : null;
  if (Array.isArray(fieldErrors)) {
    return fieldErrors.map((e) => trimEnd(e, ' ;,.')).join('; ') || null;
  }
  return (fieldErrors && trimEnd(fieldErrors, ' ;,.')) || null;
};

const pluralize = (
  value: number,
  singularSuffix: string,
  pluralSuffix?: string,
) => {
  if (pluralSuffix) {
    return `${value.toLocaleString()} ${
      value === 1 ? singularSuffix : pluralSuffix
    }`;
  }

  return `${value.toLocaleString()} ${singularSuffix}${value === 1 ? '' : 's'}`;
};

const createFileAndDownload = (
  fileName: string,
  content: string | number | boolean,
) => {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:text/plain;charset=utf-8,${encodeURIComponent(content)}`,
  );
  element.setAttribute('download', fileName);
  element.style.display = 'none';
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/* Copies a string to the clipboard */
const copyToClipboard = (text: string) => {
  let result: 'success' | 'error' = 'success';

  // use the new navigator.clipboard API, if supported; else, use the old commandExec() method
  try {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).catch(() => {
        result = 'error';
      });
    } else {
      const el = document.createElement('textarea');
      el.value = text;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
  } catch (err) {
    result = 'error';
  }
  return result;
};

export {
  getFieldError,
  hasFieldErrors,
  pluralize,
  copyToClipboard,
  createFileAndDownload,
};
