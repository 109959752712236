/* eslint-disable no-underscore-dangle */
import EnumProperty from './property';
import * as Types from './types';

class Enum {
  readonly _props: Record<string, Types.EnumProperty>;

  [k: string]: unknown | Types.EnumProperty;

  constructor(props: Record<string, Types.EnumProperty>) {
    this._props = props;
  }

  get items() {
    return Object.values(this._props);
  }

  get gqlValues() {
    return this.items.map((i) => i.gql);
  }

  get values() {
    return this.items.map((i) => i.value);
  }

  get labels() {
    return this.items.map((i) => i.label);
  }

  get keys() {
    return Object.keys(this._props);
  }

  toString() {
    return `Enum ${JSON.stringify(this._props, null, 2)}`;
  }

  labelByValue(value: Types.EnumValue) {
    return this.items.find((i) => i.value === value)?.label || '';
  }
}

const enumFactory = <T extends Types.EnumDefinition>(
  definition: T,
): Types.Enum<T> => {
  const props: Record<string, Types.EnumProperty> = {};
  const instance = new Enum(props);

  Object.keys(definition).forEach((key) => {
    const v = definition[key];
    const [value, label] = Array.isArray(v) ? v : [v, v];
    const property = new EnumProperty(value, label);
    props[key] = property;
    instance[key] = property;

    // not sure we really need this:
    instance[property.gql] = property;
    instance[value] = property;
  });

  Object.freeze(instance._props);
  return Object.freeze(instance) as Types.Enum<T>;
};

export default enumFactory;
