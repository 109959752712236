import { snakeCase } from 'lodash';

export const convertToGql = (
  val: string | number | unknown,
): string | never => {
  switch (typeof val) {
    case 'string': {
      if (!val.includes('_')) {
        return snakeCase(val).toUpperCase();
      }
      // let's preserve _ symbols in the string (QGL allows to have multiple _ in a row)
      const parts = val.split('_');
      return parts
        .reduce(
          (acc, value, index) =>
            `${acc}${index && value ? '_' : ''}${
              value ? snakeCase(value) : '_'
            }`,
          '',
        )
        .toUpperCase();
    }
    case 'number':
      return String(val);
    default:
      throw Error('Unknown value type');
  }
};

export const abbreviate = (value: string) =>
  value
    .replace(/[^a-z0-9\s]/gi, '')
    .split(/\s/)
    .reduce((accumulator, word) => accumulator + word.charAt(0), '')
    .slice(0, 2);
