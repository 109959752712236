/* eslint-disable no-underscore-dangle */
import { convertToGql } from '@helpers/strings';

import * as Types from './types';

class EnumProperty<T extends Types.EnumValue> {
  label: string;

  value: T;

  gql: string;

  constructor(value: T, label?: unknown) {
    this.value = value;
    this.label = `${label ?? value}`;
    this.gql = convertToGql(value);
    // eslint-disable-next-line no-constructor-return
    return Object.freeze(this);
  }

  valueOf() {
    return this.value;
  }

  toString() {
    return this.value;
  }
}

export default EnumProperty;
