import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Link as MuiLink,
  TextField,
  Typography,
} from '@mui/material';
import { Form, Formik, FormikConfig, FormikErrors, useFormik } from 'formik';
import { gql } from 'graphql-request';
import { isEmpty } from 'lodash';
import Router from 'next/router';
import { stringify } from 'query-string';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { AFFILIATE_SKIP_ORG_SELECTION_URL } from '@apps/Affiliate/helpers/urls';
import {
  ActionError,
  ALL_FIELD,
  getClientGenericError,
  getClientNetworkError,
  GQLError,
  gqlMutator,
  processGQLErrors,
} from '@helpers/api';
import { getFieldError } from '@helpers/ui';
import { apiURLs, homeURL, selectOrganizationURL } from '@helpers/urls';
import { getRelease } from '@helpers/utils';
import { useRouteString, useSession, useToastNotification } from '@hooks';

import Dialog from './Dialogs/Dialog';
import DialogActions from './Dialogs/DialogActions';
import DialogContent from './Dialogs/DialogContent';
import DialogHeader from './Dialogs/DialogHeader';
import FormErrors from './Forms/FormErrors';
import FormikTextField from './Forms/FormikTextField';
import GenericErrors from './Forms/GenericErrors';
import GoogleSSOButton from './GoogleSSOButton';

const sendResetPasswordEmailMutation = gql`
  mutation sendResetPasswordEmail($email: String!) {
    userOperations {
      sendResetPasswordEmail(input: { email: $email }) {
        ok
      }
    }
  }
`;

type ResetValues = { email: string };

type ResetDialogProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
};

const ResetDialog = ({ open, setOpen }: ResetDialogProps) => {
  const { notify } = useToastNotification();

  const { mutateAsync } = useMutation(
    gqlMutator(sendResetPasswordEmailMutation),
  );

  const handlePasswordReset = useCallback<
    FormikConfig<ResetValues>['onSubmit']
  >(
    async (submitValues, actions) => {
      actions.setErrors({});
      let data;
      let formErrors: ActionError | null = {};
      try {
        data = await mutateAsync(submitValues);
      } catch (e) {
        formErrors = processGQLErrors(e as GQLError[], [
          'userOperations',
          'sendResetPasswordEmail',
        ]);
        actions.setErrors(formErrors as FormikErrors<ResetValues>);
        return;
      }

      if (!data.userOperations.sendResetPasswordEmail.ok) {
        actions.setErrors({
          [ALL_FIELD]: 'Something went wrong, please try again',
        } as any);
      } else {
        setOpen(false);
        notify('A password reset link has been emailed to you.');
      }
    },
    [mutateAsync, notify, setOpen],
  );

  return (
    <>
      <Dialog
        aria-describedby="password-reset-dialog-description"
        aria-labelledby="password-reset-dialog-title"
        onClose={() => setOpen(false)}
        open={open}
      >
        <DialogHeader
          onClose={() => setOpen(false)}
          title="Forgot your password?"
        />
        <Formik
          initialValues={{ email: '' }}
          onSubmit={handlePasswordReset}
          validateOnBlur={false}
          validateOnMount={false}
          validationSchema={yup.object({ email: emailValidator })}
          enableReinitialize
        >
          {({ errors, isSubmitting }) => (
            <Form noValidate>
              <DialogContent>
                <Typography
                  color="text.secondary"
                  id="password-reset-dialog-description"
                >
                  Enter your email address to reset your password.
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <FormikTextField
                    autoComplete="email"
                    label="Email address"
                    name="email"
                    type="email"
                    fullWidth
                    required
                  />
                </Box>
                <FormErrors errors={errors} />
              </DialogContent>
              <DialogActions
                onSecondaryButtonClick={() => setOpen(false)}
                primaryButtonText="Reset password"
                primaryDisabled={isSubmitting}
                primaryType="submit"
                secondaryButtonText="Close"
                secondaryDisabled={isSubmitting}
              />
              <GenericErrors errors={errors} />
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

const emailValidator = yup
  .string()
  .email('Enter a valid email.')
  .required('Email is required.');

const validationSchema = yup.object({
  email: emailValidator,
  password: yup.string().required('Password is required.'),
  remember: yup.boolean(),
});

const initialValues = {
  email: '',
  password: '',
  remember: true,
};

const SignInForm = () => {
  const { updateSession, organizations, user } = useSession();
  const [googleErrors, setGoogleErrors] = useState<ActionError | null>(null);
  const [nextUrl, ,] = useRouteString('nextUrl', '', { readOnly: true });

  const skipOrgSelect = (nextUrl: string) => {
    // Skip choosing an organization since we set intended siteGuid in query param for affiliate widget creation
    return nextUrl?.includes(AFFILIATE_SKIP_ORG_SELECTION_URL);
  };

  useEffect(() => {
    const effect = async () => {
      if (user.isSignedIn) {
        let redirectURL;
        if (organizations.length > 1 && !skipOrgSelect(nextUrl)) {
          // redirect to org selection if user has membership in several organizations
          redirectURL = `${selectOrganizationURL}${
            nextUrl ? `?nextUrl=${nextUrl}` : ''
          }`;
        } else if (nextUrl) {
          // redirect to nextUrl if user is member in one organization
          redirectURL = nextUrl;
        } else {
          // otherwise to home page
          redirectURL = homeURL;
        }
        // ensure redirect validity
        redirectURL =
          redirectURL &&
          redirectURL.startsWith('/') &&
          !redirectURL.endsWith('.json') &&
          !redirectURL.toLowerCase().includes('forbidden')
            ? redirectURL
            : selectOrganizationURL;

        Router.push(redirectURL);
      }
    };
    effect();
  }, [nextUrl, organizations.length, user.isSignedIn]);

  // password reset dialogue
  const [resetOpen, setResetOpen] = useState(false);

  const {
    errors,
    setErrors,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    touched,
    values,
  } = useFormik({
    initialValues,
    onSubmit: async (formValues, actions) => {
      actions.setErrors({});
      setGoogleErrors({});

      let response: Response | null = null;
      let error: unknown | null = null;
      try {
        response = await fetch(apiURLs.signin, {
          body: stringify({
            ...formValues,
            signInRelease: getRelease(),
          }),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          method: 'POST',
          credentials: 'same-origin',
        });
      } catch (e) {
        error = getClientNetworkError();
      }
      if (!error && response) {
        error = response.ok
          ? (await response.json()).error
          : getClientGenericError();
      }

      if (error) {
        // it is transit gql errors of login mutation
        const formErrors = processGQLErrors(error as GQLError[], ['login']);
        actions.setErrors(formErrors as any);
      } else {
        // runs only on the client
        await updateSession({ redirectToSignIn: false });
      }
    },
    validationSchema,
  });

  const sendToken = useCallback(
    async ({ id_token }: { id_token: string }) => {
      setErrors({});
      setGoogleErrors({});
      setSubmitting(true);

      let response: Response | null = null;
      let error: unknown | null = null;
      try {
        response = await fetch(apiURLs.signinGoogle, {
          body: stringify({
            idToken: id_token,
            signInRelease: getRelease(),
          }),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          method: 'POST',
          credentials: 'same-origin',
        });
      } catch (e) {
        error = getClientNetworkError();
      }
      if (!error && response) {
        error = response.ok
          ? (await response.json()).error
          : getClientGenericError();
      }

      if (error) {
        // it is transit gql errors of login mutation
        const formErrors = processGQLErrors(error as GQLError[], [
          'googleLogin',
        ]);
        setSubmitting(false);
        setGoogleErrors(formErrors);
      } else {
        // runs only on the client
        await updateSession({ redirectToSignIn: false });
        setSubmitting(false);
      }
    },
    [setErrors, setSubmitting, updateSession],
  );

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '400px',
        minWidth: '320px',
      }}
    >
      <Box
        sx={{
          width: '100%', // Fix IE11 issue.
        }}
      >
        <form
          onSubmit={handleSubmit}
          noValidate
        >
          <TextField
            InputLabelProps={{
              shrink: true, // fixes an intermittent label/value overlap issue
            }}
            autoComplete="email"
            error={Boolean(getFieldError(errors, touched, 'email'))}
            helperText={getFieldError(errors, touched, 'email') || ' '}
            id="email"
            label="Email address"
            margin="none"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: 'background.paper',
              },
            }}
            type="email"
            value={values.email}
            fullWidth
            required
          />
          <TextField
            InputLabelProps={{
              shrink: true, // fixes an intermittent label/value overlap issue
            }}
            autoComplete="current-password"
            error={Boolean(getFieldError(errors, touched, 'password'))}
            helperText={getFieldError(errors, touched, 'password') || ' '}
            id="password"
            label="Password"
            margin="none"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            sx={{
              '& .MuiInputBase-root': {
                backgroundColor: 'background.paper',
              },
            }}
            type="password"
            value={values.password}
            fullWidth
            required
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.remember}
                color="primary"
                name="remember"
                onChange={handleChange}
              />
            }
            sx={{
              pl: 2,
              '& .MuiFormControlLabel-label': {
                fontSize: '12px',
              },
            }}
            label="Remember me"
          />
          <FormErrors errors={errors} />
          <LoadingButton
            disabled={isSubmitting}
            id="signin-button"
            loading={isSubmitting}
            size="large"
            sx={{
              fontSize: '16px',
              mt: 2,
              mb: 1,
            }}
            type="submit"
            variant="contained"
            fullWidth
          >
            Sign in
          </LoadingButton>
        </form>
        <MuiLink
          component="button"
          onClick={(e) => {
            e.preventDefault(); // prevent from submitting
            setResetOpen(true);
          }}
          tabIndex={-1}
          variant="body2"
          sx={{
            pl: 2,
            fontSize: '12px',
          }}
        >
          Forgot password?
        </MuiLink>
        <Divider
          sx={{
            my: 2,
            '&::before, &::after': {
              borderColor: 'primary.main',
            },
          }}
        >
          <Typography
            color="textPrimary"
            variant="caption"
          >
            OR
          </Typography>
        </Divider>
        <FormErrors errors={googleErrors} />
        <GoogleSSOButton
          loading={isSubmitting}
          onSuccess={sendToken}
        />
      </Box>

      <GenericErrors errors={isEmpty(errors) ? googleErrors : errors} />

      <ResetDialog
        open={resetOpen}
        setOpen={setResetOpen}
      />
    </Box>
  );
};

export default SignInForm;
