import { ReactNode } from 'react';

import DashboardLayout from './DashboardLayout';
import Layout from './Layout';
import OrganicSloganWithContent from './OrganicSloganWithContent';

type WelcomePageLayoutProps = {
  title?: string;
  indexable?: boolean;
  children: ReactNode;
};

const WelcomePageLayout = ({
  title = 'Welcome',
  indexable,
  children,
}: WelcomePageLayoutProps) => {
  return (
    <Layout
      meta={{
        title,
        indexable,
      }}
    >
      <DashboardLayout>
        <OrganicSloganWithContent>{children}</OrganicSloganWithContent>
      </DashboardLayout>
    </Layout>
  );
};

export default WelcomePageLayout;
