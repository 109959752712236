import Head from 'next/head';
import PropTypes from 'prop-types';
import { ReactNode } from 'react';

export type LayoutProps = {
  children: ReactNode;
  meta: {
    title: string;
    indexable?: boolean;
  };
  titleTagSuffix?: string;
  addTitleTagSuffix?: boolean;
};

const Layout = ({
  children,
  meta,
  titleTagSuffix,
  addTitleTagSuffix,
}: LayoutProps) => (
  <>
    <Head>
      <title>{`${meta.title}${
        addTitleTagSuffix ? ` - ${titleTagSuffix}` : ''
      }`}</title>
      {!meta?.indexable && (
        <meta
          content="noindex, follow"
          name="robots"
        />
      )}
    </Head>
    {children}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    indexable: PropTypes.bool,
  }),
  titleTagSuffix: PropTypes.string,
  addTitleTagSuffix: PropTypes.bool,
};

Layout.defaultProps = {
  meta: {
    indexable: true,
  },
  addTitleTagSuffix: true,
  titleTagSuffix: 'Organic',
};

export default Layout;
