import { Box } from '@mui/material';
import { ReactNode } from 'react';

type DashboardLayoutProps = {
  children: ReactNode;
};

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  return <Box sx={{ display: 'flex' }}>{children}</Box>;
};

export default DashboardLayout;
