/**
 * Organic Affiliate URLs
 * If you change these, don't forget to update Python/API tests!
 */

import { stringifyUrl } from 'query-string';

const AFFILIATE_SKIP_ORG_SELECTION_URL = '/apps/affiliate/integrations';

// hoisted values for use by generation function
const analyticsPageURLs = {
  home: '/apps/affiliate/analytics/product', // currently no base analytics dashboard page (will be implemented later)
  product: '/apps/affiliate/analytics/product',
  content: '/apps/affiliate/analytics/content',
};

// generate a deep-linkable product page URL
const generateProductPageURL = (guid, query = {}, fragmentIdentifier = null) =>
  stringifyUrl({
    url: `/apps/affiliate/products/${guid}`,
    query, // query params are optional
    fragmentIdentifier, // fragment identifier is optional
  });

// generate a deep-linkable analytics page URL
const generateAnalyticsPageURL = (
  reportType,
  query = {},
  fragmentIdentifier = null,
) => {
  if (!analyticsPageURLs[reportType]) {
    console.error('Invalid analytics page type'); // eslint-disable-line no-console
    return;
  }

  return stringifyUrl({
    url: analyticsPageURLs[reportType],
    query, // query params are optional
    fragmentIdentifier, // fragment identifier is optional
  });
};

const appAffiliateURLs = {
  parentURL: '/apps/affiliate',
  home: '/apps/affiliate',
  products: '/apps/affiliate/products',
  analytics: {
    ...analyticsPageURLs,
    analyticsPageURL: generateAnalyticsPageURL,
  },
  widgetCreation: {
    home: AFFILIATE_SKIP_ORG_SELECTION_URL,
  },
  widgets: {
    home: '/apps/affiliate/widgets',
    productCard: '/apps/affiliate/widgets/product-card',
    productCarousel: '/apps/affiliate/widgets/product-carousel',
  },
  search: '/apps/affiliate#search',
  settings: {
    home: '/apps/affiliate/settings',
    merchants: '/apps/affiliate/settings#merchants',
    amazonIDs: '/apps/affiliate/settings#amazon-ids',
    CSVUploads: '/apps/affiliate/settings#csv-upload',
  },
  productPageURL: generateProductPageURL,
};

export { appAffiliateURLs, AFFILIATE_SKIP_ORG_SELECTION_URL };
