import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';

import Logo from '../../assets/images/logo-color-extra-small.svg';

const OrganicSloganWithContent = ({ children }: PropsWithChildren) => {
  const { breakpoints } = useTheme();
  const desktop = breakpoints.up('sm');
  const mobile = breakpoints.down('sm');

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100%',
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat',
        [desktop]: {
          backgroundImage: 'url("/images/signin-background-desktop.svg")',
          px: 3,
          pt: 3,
        },
        [mobile]: {
          backgroundImage: 'url("/images/signin-background-mobile.svg")',
          px: 4,
          pt: 10,
        },
      }}
    >
      <Stack
        sx={{
          [mobile]: {
            alignItems: 'center',
          },
        }}
      >
        <a
          href={`https://${process.env.NEXT_PUBLIC_APP_DOMAIN!.replace(
            'app.',
            '',
          )}`}
        >
          <Logo />
        </a>
      </Stack>
      <Typography
        align="center"
        sx={{
          py: 2,
          [desktop]: {
            mt: 8,
            mb: 15,
          },
          [mobile]: {
            my: 5,
          },
        }}
      >
        <Typography
          component="span"
          variant="h1"
          sx={{
            display: 'inline-block',
            [mobile]: {
              maxWidth: 360,
            },
          }}
        >
          Sustainable Growth For Publishers
        </Typography>
      </Typography>
      <Stack
        alignItems="center"
        mb={10}
      >
        {children}
      </Stack>
    </Box>
  );
};

export default OrganicSloganWithContent;
