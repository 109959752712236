import { WelcomePageLayout } from '@components';
import SignInForm from '@components/SignInForm';
import { withSsrSession } from '@helpers/server';
import { homeURL } from '@helpers/urls';

const UserSignInPage = () => (
  <WelcomePageLayout title="Sign in">
    <SignInForm />
  </WelcomePageLayout>
);

export default UserSignInPage;

export const getServerSideProps = withSsrSession(homeURL, true);
