import { Box, DialogContent as MUIDialogContent, SxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

type DialogContentProps = {
  sx?: SxProps;
};

const DialogContent = ({
  children,
  sx,
}: PropsWithChildren<DialogContentProps>) => {
  return (
    <Box
      sx={{
        overflowY: 'scroll', // Without this, proper zIndex is not applied on dialog header and actions
        ...sx,
      }}
    >
      <MUIDialogContent>{children}</MUIDialogContent>
    </Box>
  );
};

export default DialogContent;
