import { faTimes as iconClose } from '@fortawesome/pro-solid-svg-icons/faTimes';
import {
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { PropsWithChildren, ReactNode } from 'react';

import Icon from '@components/Icon';

type DialogHeaderProps = {
  onClose?: () => void;
  secondaryText?: string;
  title: string | ReactNode;
};

const DialogHeader = ({
  children,
  onClose,
  secondaryText,
  title,
}: PropsWithChildren<DialogHeaderProps>) => {
  return (
    <DialogTitle
      sx={{
        px: 0,
        pt: 0.5,
        pb: 0,
        position: 'sticky',
        backgroundColor: ({ palette }) => palette.background.paper,
        top: 0,
      }}
    >
      {secondaryText && (
        <Typography
          color="textSecondary"
          component="div"
          sx={{ pl: 2 }}
          variant="subtitle1"
        >
          {secondaryText}
        </Typography>
      )}
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        pl={2}
        pr={1}
        pb={0.5}
        pt={0}
      >
        <Typography
          component="div"
          variant="h4"
        >
          {title}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ ml: 1 }}
        >
          {children && <>{children}</>}
          {onClose ? (
            <IconButton
              onClick={onClose}
              sx={{ color: 'text.secondary' }}
              title="Close"
            >
              <Icon icon={iconClose} />
            </IconButton>
          ) : null}
        </Stack>
      </Stack>
      <Divider orientation="horizontal" />
    </DialogTitle>
  );
};

export default DialogHeader;
