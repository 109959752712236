import { formatISO, parseISO } from 'date-fns';
import { isBoolean, isEqual, isNumber, omit } from 'lodash';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  GuidString,
  isDate,
  isGuidString,
  isISOString,
} from '@helpers/typeGuards';
import { getHref, getURLQuery, updateURLQuery } from '@helpers/urls';

import usePrevious from './usePrevious';
import useSetLock from './useSetLock';

type RouteParamOptions<JS, URL> = {
  initial?: JS;
  serializer?: (v: JS) => URL;
  parser?: (v: URL, initial?: JS) => JS;
  comparator?: (value: unknown, other: unknown) => boolean;
  replace?: boolean;
  partial?: boolean;
  setOnMount?: boolean;
  readOnly?: boolean; // if set to true, setValue updates only internal state, without affecting url / router
};

// to prevent race condition between hooks
const locksStorage = new Set<string>();

type UseRouteParamReturnType<JS> = [JS, (value: JS) => void];

const useRouteParam = <JS = string, URL = string>(
  key: string,
  options: RouteParamOptions<JS, URL>,
): UseRouteParamReturnType<JS> => {
  const {
    initial,
    serializer,
    parser,
    replace = true,
    partial = true,
    setOnMount = false,
    readOnly = false,
    comparator = isEqual,
  } = options;

  const router = useRouter();
  const href = decodeURI(getHref());
  const previous = usePrevious({ href });

  const { isLocked, setLock, releaseLock } = useSetLock(
    Boolean(key),
    locksStorage,
  );

  const parsedValue = useMemo(() => {
    const query = getURLQuery(href);
    if (!key || !(key in query)) {
      return initial;
    }
    if (parser) {
      return parser(query[key] as unknown as URL, initial);
    }
    const value = query[key];
    if (value === '' || typeof value === 'undefined') {
      return initial;
    }
    return value;
  }, [key, href, initial, parser]);

  const [queryValue, setQueryValue] = useState<JS>(parsedValue as JS);

  const updateUrl = useCallback(
    (value: JS | URL) => {
      if (!key) {
        return;
      }

      updateURLQuery(
        router,
        {
          [key]: value,
        },
        undefined, // hash
        partial,
        replace,
      );
    },
    [router, key, partial, replace],
  );

  const serialize = useCallback(
    (v: JS) => (serializer ? serializer(v) : v),
    [serializer],
  );
  const isSame = useCallback(
    (a: unknown, b: unknown) => comparator(a, b),
    [comparator],
  );

  const setValue = useCallback(
    (newValue: JS, force = false) => {
      const id = `${key}_${newValue}`;
      if (isLocked(id)) return;
      const serialized = serialize(newValue);
      if (isSame(serialized, serialize(queryValue)) && !force) return;

      setLock(id, key);
      setQueryValue(newValue);
      if (!readOnly) {
        setTimeout(() => {
          updateUrl(serialized);
        }, 0);
      }
      setTimeout(() => {
        releaseLock(id, key);
      }, 0);
    },

    [
      key,
      isLocked,
      serialize,
      isSame,
      queryValue,
      setLock,
      readOnly,
      updateUrl,
      releaseLock,
    ],
  );

  // update value on programmatic url change
  useEffect(() => {
    if (!key || isLocked(key) || !previous || previous.href === href) {
      return;
    }

    setLock(key);
    setQueryValue(parsedValue as JS);
    setTimeout(() => {
      releaseLock(key);
    }, 0);
  }, [key, href, parsedValue, previous, isLocked, setLock, releaseLock]);

  // set url param on mount
  useEffect(() => {
    if (!key || !setOnMount || key in getURLQuery(href)) return;
    setValue(initial as JS, true);
  });

  return [queryValue, setValue];
};

export default useRouteParam;

export const useRouteArray = <T = string>(
  key: string,
  initial: T[] = [],
  variants?: T[],
) => {
  const [values, setValues] = useRouteParam<T[], T[]>(key, {
    // ensure received and given values are arrays, query-string parses and serializes them automatically
    serializer: useCallback((v: T | T[]) => (Array.isArray(v) ? v : []), []),
    parser: useCallback((v: T | T[]) => (Array.isArray(v) ? v : []), []),
    initial,
  });

  const filteredValues = useMemo(() => {
    if (!Array.isArray(variants)) {
      return values;
    }
    return values.filter((i) => variants.find((j) => isEqual(i, j)));
  }, [values, variants]);

  return [filteredValues, setValues] as UseRouteParamReturnType<T[]>;
};

type TypedRouteParamOptions<JS, URL = string> = Omit<
  RouteParamOptions<JS, URL>,
  'initial' | 'serializer' | 'parser'
>;

type UseRouteDateOptions<T> = TypedRouteParamOptions<T> & {
  representation?: 'date' | 'complete' | 'time' | undefined;
};

export const useRouteDate = <T extends Date | null = Date>(
  key: string,
  initial: T,
  options: UseRouteDateOptions<T> = {},
) =>
  useRouteParam<T, string>(key, {
    ...omit(options, 'representation'),
    initial,
    serializer: useCallback(
      (val: T) =>
        isDate(val)
          ? formatISO(val, { representation: options.representation || 'date' })
          : '',
      [options.representation],
    ),
    parser: useCallback(
      (val = '') => {
        if (!isISOString(val)) {
          return initial;
        }
        const date = parseISO(val);
        return (isDate(date) ? date : initial) as T;
      },
      [initial],
    ),
  });

type UseRouteStringOptions = TypedRouteParamOptions<string, string>;
export const useRouteString = (
  key: string,
  initial = '',
  options: UseRouteStringOptions = {},
) =>
  useRouteParam<string, string>(key, {
    ...options,
    initial,
    serializer: useCallback((val: string) => `${val ?? ''}`, []),
    parser: useCallback((val = '') => `${val ?? initial}`, [initial]),
  });

type UseRouteGUIDOptions<T> = TypedRouteParamOptions<T, string>;
export const useRouteGUID = <T extends GuidString | null = GuidString>(
  key: string,
  initial: T,
  options: UseRouteGUIDOptions<T> = {},
) =>
  useRouteParam<T, string>(key, {
    ...options,
    initial,
    serializer: useCallback((val: T) => (isGuidString(val) ? val : ''), []),
    parser: useCallback(
      (val: string) => (isGuidString(val) ? (val as T) : initial),
      [initial],
    ),
  });

type UseRouteNumber = TypedRouteParamOptions<number>;
export const useRouteNumber = (
  key: string,
  initial: number,
  options: UseRouteNumber = {},
) =>
  useRouteParam<number, string>(key, {
    ...options,
    initial,
    serializer: useCallback(
      (val: number) => (isNumber(val) ? `${val}` : ''),
      [],
    ),
    parser: useCallback(
      (val: string) => {
        const n = Number(val);
        if (Number.isNaN(n)) {
          return initial;
        }
        return n;
      },
      [initial],
    ),
  });

type UseRouteBoolean = TypedRouteParamOptions<boolean>;
export const useRouteBoolean = (
  key: string,
  initial: boolean,
  options: UseRouteBoolean = {},
) =>
  useRouteParam<boolean, string>(key, {
    ...options,
    initial,
    serializer: (val) => {
      return isBoolean(val) ? `${val}` : '';
    },
    parser: (val) => {
      if (val === 'true') {
        return true;
      }
      if (val === 'false') {
        return false;
      }
      return initial;
    },
  });
