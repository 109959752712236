import { Box } from '@mui/material';
import Script from 'next/script';
import { useCallback, useRef } from 'react';

import { isRunningTest, logger } from '@helpers/utils';

declare global {
  interface Window {
    google?: typeof google;
  }
}

type GoogleSSOButtonProps = {
  loading: boolean;
  onSuccess: (props: { id_token: string }) => void;
};

const GoogleSSOButton = ({ onSuccess, loading }: GoogleSSOButtonProps) => {
  const ref = useRef<HTMLDivElement>();

  const handleClick = useCallback<
    NonNullable<google.accounts.id.IdConfiguration['callback']>
  >(
    (response) => {
      /*  Short circuit to support Google Auth testing without relying on its API.
          See the tests.test_signin_signout.test_google_signin_action test */
      if (isRunningTest) {
        onSuccess({ id_token: 'fakeTokenValue' });
        return;
      }

      if (loading || !google) return;
      onSuccess({ id_token: response.credential });
    },
    [loading, onSuccess],
  );

  return (
    <>
      <Script
        id="google-gsi"
        src="https://accounts.google.com/gsi/client?hl=en"
        strategy="afterInteractive"
        onReady={() => {
          google.accounts.id.initialize({
            client_id: process.env.GOOGLE_WEB_CLIENT_ID!,
            callback: handleClick,
          });
          google.accounts.id.renderButton(ref.current!, {
            type: 'standard',
            theme: 'filled_blue',
            width: '400px',
            text: 'signin_with',
            locale: 'en',
            /*  Short circuit to support Google Auth testing without relying on its API. */
            click_listener: isRunningTest
              ? () => handleClick({} as google.accounts.id.CredentialResponse)
              : undefined,
          });
        }}
        onError={(e) => {
          logger.error({ error: e as Error });
        }}
      />
      <Box
        id="google-signin-button"
        ref={ref}
      />
    </>
  );
};

export default GoogleSSOButton;
