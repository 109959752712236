import Router from 'next/router';
import { useCallback, useContext, useMemo } from 'react';

import {
  SessionContext,
  SessionContextType,
} from '@components/SessionProvider';
import { jsonFetcher } from '@helpers/api';
import { PermissionMode, SiteMode, hasPermissions } from '@helpers/permissions';
import { GuidString } from '@helpers/typeGuards';
import { apiURLs, signInURL } from '@helpers/urls';

export type CurrentSession = Omit<SessionContextType, 'permissions'> & {
  signout: (redirectTo?: string) => PromiseLike<void>;
  checkPermissions: (...args: any[]) => boolean;
};

function useSession() {
  const {
    activeOrganizationGuid,
    organizations,
    permissions,
    updateSession,
    user,
  } = useContext(SessionContext);

  const signout = useCallback(
    async (redirectTo = signInURL) => {
      await jsonFetcher(apiURLs.signout);
      await updateSession({ redirectToSignIn: false, reset: true });
      if (redirectTo) {
        await Router.push(redirectTo);
      }
    },
    [updateSession],
  );

  const checkPermissions = useCallback(
    (
      perms: string | string[],
      sites: GuidString | GuidString[] | null,
      permMode: PermissionMode,
      siteMode: SiteMode,
    ) => hasPermissions(permissions, perms, sites, permMode, siteMode),
    [permissions],
  );

  return useMemo(
    () => ({
      activeOrganizationGuid,
      organizations,
      checkPermissions,
      signout,
      updateSession,
      user,
    }),
    [
      activeOrganizationGuid,
      organizations,
      signout,
      updateSession,
      user,
      checkPermissions,
    ],
  ) as CurrentSession;
}

export default useSession;
