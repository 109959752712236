import { faExclamationCircle as iconError } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { Alert, Snackbar } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';

import {
  ERROR_MSG_FORBIDDEN,
  ERROR_MSG_GENERIC,
  ERROR_MSG_NETWORK,
} from '@helpers/api';
import { useSession } from '@hooks';

import Icon from '../Icon';

/*
  It is a common handler of very generic errors:
    - Unauthorized, forbidden;
    - Network error;
    - Generic unknown error.
  The nature of these errors implies that only one is active at the moment.
  It is intended for usage with mutations or queries.
 */

export type GenericErrorsProps = {
  errors?:
    | Record<string, any>
    | null
    | {
        genericErrors?: {
          unauthorized?: boolean;
          forbidden?: boolean;
          network?: boolean;
          generic?: boolean;
        };
      };
};

const GenericErrors = ({ errors }: GenericErrorsProps) => {
  const { updateSession } = useSession();
  const [open, setOpen] = useState(true);
  const [updated, setUpdated] = useState(false);
  const onClose = useCallback(
    (event: React.SyntheticEvent | Event, reason?: string) => {
      // don't dismiss the Snackbar message on ESC or clickaway listener click
      if (reason !== 'clickaway' && reason !== 'escapeKeyDown') {
        setOpen(false);
      }
    },
    [],
  );
  const { unauthorized, forbidden, generic, network } =
    errors?.genericErrors || {};

  // show errors with snackbar
  useEffect(
    () => setOpen(Boolean(forbidden || generic || network)),
    [forbidden, generic, network],
  );

  if (unauthorized && !updated) {
    setUpdated(true);
    updateSession();
    return null;
  }
  if (forbidden || generic || network) {
    const msg =
      (forbidden && ERROR_MSG_FORBIDDEN) ||
      (network && ERROR_MSG_NETWORK) ||
      (generic && ERROR_MSG_GENERIC);
    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={onClose}
        open={open}
      >
        <Alert
          icon={<Icon icon={iconError} />}
          onClose={onClose}
          severity="error"
          variant="filled"
        >
          {msg}
        </Alert>
      </Snackbar>
    );
  }
  return null;
};

export default GenericErrors;
